import { envIsServer } from '@/helper/env'
import { dynamicActivate, extractLocale, localeDefault } from '@/helper/i18n'

export function onBeforeRoute(pageContext) {
  let { urlOriginal, locale, path } = pageContext
  const { urlWithoutLocale, locale: currentLocale } = extractLocale(urlOriginal)
  if (!path) {
    path = urlWithoutLocale
    locale = currentLocale || localeDefault
  }
  // 修复服务端 ssr 多语言没同步问题
  // dynamicActivate(locale)

  return {
    pageContext: {
      urlOriginal: envIsServer ? urlWithoutLocale : path,
      path,
      // locale,
    },
  }
}
